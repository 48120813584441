<template>
  <div
    v-if="isLoading"
    class="ma-5"
  >
    <v-skeleton-loader
      type="image, image, image, image"
      min-height="100%"
      min-width="100%"
    />
  </div>

  <div
    v-else
    class="pb-15"
  >
    <v-item-group
      v-model="currentWindow"
      mandatory
      class="daily-report-cache-btn"
    >
      <v-item
        v-for="n in windowLength"
        :key="`daily-report-window-${n}`"
        v-slot="{active}"
        class="d-flex flex-column"
      >
        <transition name="bounceUp">
          <v-btn
            :key="`daily-report-window-toggle-btn-${n}`"
            :input-value="active"
            :color="active ? 'primary' : 'secondary'"
            icon
            :ripple="false"
            @click="toggleWindow(n)"
          >
            <v-icon>
              {{ icons.mdiCameraIris }}
            </v-icon>
          </v-btn>
        </transition>
      </v-item>
    </v-item-group>

    <v-window
      :value="currentWindow"
      vertical
      touchless
    >
      <v-window-item
        v-for="n in windowLength"
        :key="`daily-report-window-item-${n}`"
      >
        <keep-alive>
          <component
            :is="'DailyReport'"
            v-if="currentWindow === n - 1"
            :key="`daily-report-component-${n}`"
            :users="users"
          />
        </keep-alive>
      </v-window-item>
    </v-window>

    <transition name="rotate">
      <v-btn
        :key="`daily-report-window-add-btn-${windowLength}`"
        color="primary"
        fixed
        bottom
        right
        fab
        :ripple="false"
        @click="addWindow"
      >
        <v-icon large>
          {{ icons.mdiCameraIris }}
        </v-icon>
      </v-btn>
    </transition>
  </div>
</template>

<script>
import { ref, watch, provide } from '@vue/composition-api'
import { mdiCameraIris } from '@mdi/js'
import { cloneDeep } from 'lodash'
import UserApi from '@/api/waiter/User'
import VendibleApi from '@/api/waiter/Vendible'
import useQueryString from '@/views/composable/useQueryString'
import useCompInit from '@/views/composable/useCompInit'
import DailyReport from '@/views/components/daily_report/DailyReport.vue'

export default {
  components: {
    DailyReport,
  },
  setup() {
    const { route, resetQuery, addQuery } = useQueryString()
    const { isLoading, initWith } = useCompInit()
    const currentWindow = ref(0)
    const windowLength = ref(1)
    const routeQueryHistories = ref([])
    const users = ref([])
    const vendibles = ref({})
    provide('users', users)
    provide('vendibles', vendibles)

    const addWindow = () => {
      routeQueryHistories.value.push(cloneDeep(route.value.query))

      windowLength.value += 1
    }

    const toggleWindow = windowNum => {
      const windowIdx = windowNum - 1
      if (currentWindow.value === windowIdx) return

      routeQueryHistories.value.splice(currentWindow.value, 1, cloneDeep(route.value.query))
      currentWindow.value = windowIdx
      resetQuery(true)
      addQuery(routeQueryHistories.value[windowIdx])
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers(['cast', 'waiter', 'alliance'])
      if (res?.data) users.value = [...res.data.users.data]
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.data) vendibles.value = [...res.data.vendibles.data]
    }

    watch(windowLength, newLen => {
      currentWindow.value = newLen - 1
    })

    initWith([
      getUsers(),
      getVendibles(),
    ])

    return {
      // data
      currentWindow,
      windowLength,
      routeQueryHistories,
      users,
      isLoading,

      // methods
      addWindow,
      toggleWindow,

      icons: {
        mdiCameraIris,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

@include theme--child(v-dialog--active) using ($material) {
  .closing-dialog-card {
    background-color: map-deep-get($material, 'background') !important;
  }
}

.daily-report-cache-btn {
  position: fixed;
  right: 24px;
  top: 50%;
  z-index: 10;
}
</style>
